






















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { OCompanyList: () => import('@/components/organisms/event/company/o-company-list.vue')},

  setup(_, { root }){

    const hasAccessTo = (permission: string) => root.$store.getters['user/hasAccessTo'](permission)

    return { hasAccessTo }
  }
})
